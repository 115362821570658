module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["fr","en","de","es"],"defaultLanguage":"fr","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Percko","short_name":"Percko","start_url":"/eur/","background_color":"#fff","theme_color":"#262626","description":"Découvrez nos solutions brevetées développées avec des ostéopathes et recommandées par les experts !","display":"standalone","icon":"static/favicon.ico","lang":"fr","crossOrigin":"anonymous","localize":[{"start_url":"/eur/fr/","lang":"fr","name":"Percko","short_name":"Percko","description":"Découvrez nos solutions brevetées développées avec des ostéopathes et recommandées par les experts !"},{"start_url":"/eur/en/","lang":"en","name":"Percko","short_name":"Percko","description":"Discover our patented solutions developed with osteopaths and recommended by experts!"},{"start_url":"/eur/de/","lang":"de","name":"Percko","short_name":"Percko","description":"Entdecken Sie unsere patentierten Lösungen, die mit Osteopathen entwickelt und von Experten empfohlen wurden!"},{"start_url":"/eur/es/","lang":"es","name":"Percko","short_name":"Percko","description":"¡Descubra nuestras soluciones patentadas desarrolladas con osteópatas y recomendadas por expertos!"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"3e7c498d20128d344819284c8293afa4"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://07e14f60a5d34165baa8f0b0624d5b4f@o1061056.ingest.sentry.io/6051149","environment":"percko-eur","release":"b12ac5d102f459b7986f7ccda328c4ce2b56d1f3","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
